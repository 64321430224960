@tailwind base;
@tailwind components;
@tailwind utilities;


body {  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobile_menu {
  position: absolute;
  width: 100%;
  background-color: white;
  margin-left: -24px;
  padding-left: 30px;
}

.nav-link {
  color: rgb(16 24 40 / var(--tw-text-opacity));
  font-weight: 400;
}

.main-bg-color {
  background-color: #1d1c1c;
  color: white
}
.hero-section {
 background-size: cover;
 background-image: url(https://storage.googleapis.com/hirebot-storage/static/bg_win.png);
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* To make sure the video stays in the background */
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire background */
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Optional overlay for styling */
}